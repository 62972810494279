import { Injectable, inject } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  OrgaoResponse,
  UsuariosParams,
  UsuariosResponse,
} from "./usuarios.model";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UsuariosService {
  http = inject(HttpClient);

  usuarioUrl = `${environment.apiUrl}v2/usuario`;

  findUsuarios = (params: UsuariosParams) => {
    return this.http.get<UsuariosResponse>(`${this.usuarioUrl}`, {
      params: params as unknown as HttpParams,
    });
  };

  findAllOrgaos = () => {
    return this.http.get<OrgaoResponse[]>(`${this.usuarioUrl}/orgaos`);
  };

  logoutUsuario = (cpf: string) => {
    return this.http.delete(`${this.usuarioUrl}/${cpf}`);
  };
}
